import AboutUs from "../../components/macroCompnents/AboutUs";
import ProfileHeader from "../../components/macroCompnents/ProfileHeader";
import Layout from "../../layout/Layout";
import "./profile.scss";
import facilitiesIcon from "./../../assets/icons/facilities.png";

import FacilitiesGroup from "../../components/macroCompnents/FacilitiesGroup";
import profileIcon from "./../../assets/icons/restrurantLogo.png";
import { useState } from "react";
import shareIcon from './../../assets/icons/share3.png';
import addIcom from './../../assets/icons/add2.png'
import profileIconLeft from './../../assets/icons/profileArrowLeft.png';
import profileIconRight from './../../assets/icons/profileArrowRight.png';
export default function Profile() {
  let isAdmin = true;
  let profiledata = {
    id: 1,
    shopId: "124-234",
    city: "Jaipur",
    state: "Rajasthan",
    country: "India",
    logo: "logo",
    heading: "The Big Indian Buffet",
    website: "www.something.com",
    tags: ["5 star rated", "indian", "thai", "5 star rated", "indian", "thai"],
    description: "Indian Cusine Restaurants",
    aboutUs:
      "Lorem ipsum dolor sit amet consectetur. Donec bibendum est nisl in lacinia eget.Lorem ipsum dolor sit amet consectetur. Donec bibdolor Lorem ipsum dolor sit amet consectetur. Donec bibendum est Lorem ipsum dolor sit amet consectetur. Donec bibendum est nisl in lacinia eget.Lorem ipsum dolor sit amet consectetur.",
    activeFacility: 2,
    activePhotoIndex: 0,
    facilities: [
      {
        name: "facilities1",
        content:
          "Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.r. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet rem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetu",
      },
      {
        name: "facilities2",
        content:
          "Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.r. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet rem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetu",
      },
      {
        name: "facilities3",
        content:
          "Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.r. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet rem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetu",
      },
    ],
    photos: [
      {
        img: "link",
        heading: "heading",
        subHeading:
          "asakjsdbkahdskahsdjhasdla Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet c",
      },
      {
        img: "link",
        heading: "heading",
        subHeading:
          "asakjsdbkahdskahsdjhasdla Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet c",
      },
      {
        img: "link",
        heading: "headixzng",
        subHeading:
          "asakjsdbkahdskahsdjhasdla Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet c",
      },
      {
        img: "link",
        heading: "heading",
        subHeading:
          "asakjsdbkahdskahsdjhasdla Lorem ipsum dolor sit amet consectetuLorem ipsum dolor sit amet c",
      },
    ],
  };
  function addPhotoPopup() {}
  const [data, setData] = useState(profiledata);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  function addFacilities() {
    if (data.facilities.length <= 5) {
      let facility = {
        name: "",
        content: "",
      };
      const copyData = { ...data };
      copyData.facilities.push(facility);
      copyData["activeFacility"] = data.facilities.length - 1;
      setData(copyData);
    }
  }
  function setPrev() {
    if (activePhotoIndex > 0) {
      setActivePhotoIndex(activePhotoIndex - 1);
    }
  }
  function setNext() {
    if (activePhotoIndex + 1 < data.photos.length) {
      setActivePhotoIndex(activePhotoIndex + 1);
    }
  }

  return (
    <Layout>
      <div>
        <ProfileHeader
          shopId={data.shopId}
          img={data.logo}
          name={data.heading}
          country={data.country}
          state={data.state}
          city={data.city}
          url={data.url}
          tags={data.tags}
        />
      </div>
      <div className="aboutUs">
        <AboutUs isAdmin={isAdmin} content={data.aboutUs} />
      </div>
      <div className="facilities">
        <div className="facilitiesHeading">
          <img alt="facilities" src={facilitiesIcon} /> Facilities Offered
          {isAdmin && (
            <span>
              <button onClick={addFacilities} className="submitBrochure">
                Add More
              </button>{" "}
            </span>
          )}
        </div>
        <FacilitiesGroup
          isAdmin={isAdmin}
          activeFacility={data.activeFacility ? data.activeFacility : 0}
          facilities={data.facilities}
        />
      </div>
      <div className="photoSection">
        <div className="photoHeading">
          <img alt="facilities Icon" src={facilitiesIcon} /> Photo Section
          {isAdmin && (
            <span>
              <button onClick={addPhotoPopup} className="submitBrochure">
                Add More
              </button>{" "}
            </span>
          )}
        </div>
        <div>
          <div className="activeImageContent">
            <img alt="profile Icon" width="100" height="100" src={profileIcon} />
            <div className="photoCardHeading" contentEditable={data.isAdmin}>
              {data.photos[activePhotoIndex].heading}
            </div>
            <div className="photoCardSubHeading" contentEditable={data.isAdmin}>
              {data.photos[activePhotoIndex].subHeading}
            </div>
          </div>
          <div className="scrollpic">
            <div onClick={setPrev}><img alt="prev" src={profileIconLeft}/></div>

            {data.photos.map((item, index) => {
              return (
                <img key={index} id={index}
                alt="photos"
                  className={`${
                    index === activePhotoIndex ? "photoactive" : ""
                  }`}
                  width="40"
                  height="40"
                  src={profileIcon}
                />
              );
            })}

            <div onClick={setNext}><img alt="next" src={profileIconRight}/></div>
          </div>
        </div>
      </div>
      <div className="buttonBottom">
        <div className="flex buttonsProfile"><img alt="icon" src={addIcom}/> Add to VisitList</div>
        <div className="flex buttonsProfile"><img alt="share" src={shareIcon}/> Share Card</div>

      </div>
    </Layout>
  );
}
