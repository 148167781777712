let shopList=[]
let exhibitor=[
    {
        id:1,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },
    {
        id:2,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },
    {
        id:3,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },
    {
        id:4,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:5,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:6,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:7,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:8,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:9,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:10,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:11,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:12,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:13,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:14,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"

    },{
        id:15,
        shopId:"124-234",
        city:'Jaipur',
        state:'Rajasthan',
        country:'India',
        heading:'The Big Indian Buffet',
        tags:['5 star rated','indian','thai'],
        description:"Indian Cusine Restaurants"
    }
]
export function getShopList() {
  shopList=[
    { 
        id:2,
        name:"The Greate Indian Food",
        "description":"indian Cusine Restrurant",
        icon:"icon",
    
    },
    {   id:3,
        name:"The Greate Indian Food",
        "description":"indian Cusine Restrurant",
        icon:"icon"
    },
    {   id:4,
        name:"The Greate Indian Food",
        "description":"indian Cusine Restrurant",
        icon:"icon"
    },
    {   
        id:5,
        name:"The Greate Indian Food",
        "description":"indian Cusine Restrurant",
        icon:"icon"
    }

  ]
  return shopList;
}
export function get(){
    return shopList
}
export function getExhibitorList(){
   return exhibitor;

}
export function getVisitList(){
    return [2,5,9,4];
}
export function getVisitedList(){
    return [5,9];
}
