import Input from "../microComponents/Input";
import "./macroComponents.scss";
import React, { useState } from "react";
import { Country, State, City } from "country-state-city";


export default function UserInputFields(props) {
  const [selectedCountry, setSelectedCountry] = useState({
    country: Country.getAllCountries(),
    state: [],
    city: [],
  });
  
  //console.log(selectedCountry);
  let datamap = {
    personalDetails: {
      0: "firstName",
      1: "lastName",
      2: "company",
      3: "JobTitle",
    },
    contactDetails: {
      0: "email",
      1: "phoneNo",
    },
    companyDetails: {
      0: "country",
      1: "state",
      2: "city",
      
      3: "companySize",
      4: "industry",
      5: "website",
    },
  };
  if (props.userData.companyDetails.country) {
    let state = getState(props.userData.companyDetails.country);
    let city = [];
    if (props.userData.companyDetails.state)
      city = getCity(
        state,
        props.userData.companyDetails.country,
        props.userData.companyDetails.state
      );
    let copyData = { ...selectedCountry };
    copyData.state = state;
    copyData.city = city;
    if(copyData.state.length!==selectedCountry.state.length || copyData.city.length!==selectedCountry.city.length)
    setSelectedCountry(copyData);
    // //console.log(city,state);
  }
  function getState(item) {
    let countryCode = getCountryCode(item);
    // //console.log(cObject)
    return State.getStatesOfCountry(countryCode);
  }
  function getCity(stateList, country, state) {
    return City.getCitiesOfState(
      getCountryCode(country),
      getStateCode(stateList, state)
    );
  }
  function getCountryCode(item) {
    let cObject = selectedCountry.country.filter((obj) => obj.name === item);
    return cObject[0]?.isoCode;
  }
  function getStateCode(state, item) {
    let sObject = state.filter((obj) => obj.name === item);
    return sObject[0]?.isoCode;
  }
  let data = {
    personalDetails: [
      {
        label: "First Name",
        value: props.userData.personalDetails.firstName,
      },
      {
        label: "Last Name",
        value: props.userData.personalDetails.lastName,
      },
      {
        label: "Company",
        value: props.userData.personalDetails.company,
      },
      {
        label: "Job Title",
        value: props.userData.personalDetails.jobTitle,
      },
    ],
    contactDetails: [
      {
        label: "Email",
        value: props.userData.contactDetails.email,
      },
      {
        label: "Phone Number",
        value: props.userData.contactDetails.phoneNo,
      },
    ],
    companyDetails: [
      {
        type: "select",
        label: "Country",
        value: props.userData.companyDetails.country,
        placeholder: "Country",
        options: selectedCountry.country.map((item) => item.name),
      },
      {
        type: "select",
        label: "state",
        value: props.userData.companyDetails.state,
        options: selectedCountry.state.map((item) => item.name),
      },
      {
        type: "select",
        label: "City",
        value: props.userData.companyDetails.City,
        options: selectedCountry.city.map((item) => item.name),
      },
      {
        type: "select",
        label: "company Size",
        value: props.userData.companyDetails.companySize,
        options:["10-15","15-30","30-100","100-500"]
      },
      {
        label: "Industry",
        value: props.userData.companyDetails.industry
      },

      
      {
        label: "Website Url",
        value: props.userData.companyDetails.website,
      },
    ],
  };
  function handelCountryChange(event) {
    const newData = { ...props.userData };
    //console.log(event.target.value);
    newData.companyDetails.country = event.target.value;
    newData.companyDetails.state = "";
    newData.companyDetails.city = "";
    setData(newData);
  }
  function handelStateChange(event) {
    const newData = { ...props.userData };
    //console.log(event.target.value);
    newData.companyDetails.state = event.target.value;

    setData(newData);
    // setData(newData);
  }
  function handelCityChange(event) {
    const newData = { ...props.userData };
    //console.log(event.target.value);
    newData.companyDetails.city = event.target.value;

    setData(newData);
  }
  //console.log(data.companyDetails[0].options);
  // const [state1,setstate]=useState(data);
  function handelPersonalDetails(value, index) {
    //console.log(index, " ", value);
    const newData = { ...props.userData };
    newData.personalDetails[datamap.personalDetails[index]] = value;
    //console.log(newData);
    setData(newData);
  }
  function handelPerfessionalDetails(value, index) {
    const newData = { ...props.userData };
    newData.companyDetails[datamap.companyDetails[index]] = value;

    setData(newData);
  }
  function handelwebsiteChange(value,index){
    handelPerfessionalDetails(value,index)
  }
  function handelContactDetails(value, index) {
    const newData = { ...props.userData };
    newData.contactDetails[datamap.contactDetails[index]] = value;

    setData(newData);
  }
  function setData(data) {
    // setstate(data)
    props.onChange(data);
  }

  return (
    <div className="inputBox">
      <div className="inputBoxHeader">
        <div className="header">Fill the information</div>
        <div className="textGreen subheader">
          Please fill the form below and add all the details required.
        </div>
      </div>
      <section>
        <span className="inputGroupHeading greenUnderline">
          Personal Details
        </span>
        <div></div>
        <div>
          <div className="flex inputFieldContainer">
            {data.personalDetails.map((item, index) => {
              if (index <= 1) {
                return (
                  <Input
                    key={index}
                    label={item.label}
                    placeholder={item.placeholder}
                    value={item.value}
                    passIndex={true}
                    index={index}
                    onChange={handelPersonalDetails}
                  />
                );
              }
              return null;
            })}
          </div>
          <div>
            {data.personalDetails.map((item, index) => {
              if (index > 1) {
                return (
                  <Input
                    key={index}
                    label={item.label}
                    placeholder={item.placeholder}
                    value={item.value}
                    passIndex={true}
                    index={index}
                    onChange={handelPersonalDetails}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </section>
      <section>
        <span className="inputGroupHeading greenUnderline">
          Contact Details
        </span>
        <div></div>
        <div>
          <div className="flex   inputFieldContainer">
            {data.contactDetails.map((item, index) => {
              return (
                <Input
                  key={index}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  passIndex={true}
                  index={index}
                  onChange={handelContactDetails}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <span className="inputGroupHeading greenUnderline">
          Company Details
        </span>
        <div></div>
        <div>
          <div className="flex   inputFieldContainer">
            <div className="inputContainer">
              <label>Country</label>
              <select
                placeholder="Select Country"
                value={data.companyDetails[0].value}
                id="country"
                onChange={handelCountryChange}
              >
                {data.companyDetails[0].options.map((item,index) => {
                  return <option key={index} value={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label>State</label>
              <select
                placeholder="Select Country"
                value={data.companyDetails[1].value}
                id="state"
                onChange={handelStateChange}
              >
                {data.companyDetails[1].options.map((item,index) => {
                  return <option key={index} value={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label>State</label>
              <select
                placeholder="Select Country"
                value={data.companyDetails[2].value}
                id="state"
                onChange={handelCityChange}
              >
                {data.companyDetails[2].options.map((item,index) => {
                  return <option key={index} value={item}>{item}</option>;
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label>Company Size</label>
              <select
                placeholder="Select Country"
                value={data.companyDetails[3].value}
                id="state"
                onChange={(event)=>handelPerfessionalDetails(event.target.value,3)}
              >
                {data.companyDetails[3].options.map((item,index) => {
                  return <option key={index} value={item}>{item}</option>;
                })}
              </select>
            </div>

            {data.companyDetails.map((item, index) => {
              if(index>3){
              return (
                <Input
                  key={index}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  passIndex={true}
                  index={index}
                  onChange={(event)=>{if(index===4)handelPerfessionalDetails(event,index); else handelwebsiteChange(event,index)}}
                />
              );
              }
              return null;
            })}
          </div>
        </div>
      </section>
      <button className="submit"> Submit </button>
    </div>
  );
}
