
import "./card.scss"
import phoneIcon from './../../../assets/icons/call.png';
import mailIcon from './../../../assets/icons/mail.png'
import { useState } from "react";
export default function Card(props){
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const minSwipeDistance = 20
    console.log(props.cardName);
    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
      }
      
 const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)
      
const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe){
            isLeftSwipe?props.swipeLeft():props.swipeRight()
        } console.log('swipe', isLeftSwipe ? 'left' : 'right')
        // add your conditional logic here
      }
    function getScreenWidth(){
        return window.innerWidth
    }
    let activeIndex=props.activeIndex;
    let cardIndex=props.index
    let themeId="one"
    
    getScreenWidth()
    function getTransformx(){
        let stt=Math.abs(activeIndex-cardIndex)
       if(stt>0){
        let returnString="translateX";
        returnString=returnString +`(${cardIndex<activeIndex?'-':''}`+(120*stt)+"px)";
        let scale=`scale(${1-(0.2*stt)})`
        let returnObject={transform :`${returnString} ${scale} rotateY(-${getScreenWidth()>699?10:50}deg)`,zIndex:getZindex(stt),filter:"blur(5px)",opacity:stt>2?0:0.6}
        // console.log(returnObject)
       
        return returnObject ;
       }
       return {
        transform:"none",zIndex:1,opacity:1
       };
    }
    function getZindex(stt){
        
      return -stt;
    }
    // function testTransform(){
    //     return{transform: 'translateX(120px)  rotateY(-1deg) ',zIndex:getZindex()} 

    // }
    // style={{transform:`${cardIndex>activeIndex?getTransformx():""}`}}
    let data=props.userData;
    return (
      <div>
        {cardIndex===activeIndex && <div className="cardName">
          {props.userData.cardName}
        </div>}
        
        <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} id={cardIndex===activeIndex?"active":""}  style={getTransformx()} className={`${themeId} card`}>
           <div className="name">{data.personalDetails.firstName} {data.personalDetails.lastName}</div>
           <div className="designation">{data.personalDetails.jobTitle}</div>
           <div className="phone"><img alt="phone" src={phoneIcon}/>{data.contactDetails.phoneNo}</div>
           <div className="email"><img alt="mail" src={mailIcon}/>{data.contactDetails.email}</div>
        </div>
        </div>

    )
}