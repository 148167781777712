import './macroComponents.scss'
export default function CardManagementHeader(){
    return (
      <div className="headerGroup">
        <div className="cardHeader">
          Liberty From Dull <span><br/></span>Printed
          <span className="animatedHeader"> Business Card</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="60"
          viewBox="0 0 6 60"
          fill="none"
        >
          <g filter="url(#filter0_f_15_2423)">
            <path d="M3 2L3 58" stroke="#40E18C" strokeWidth="2" />
          </g>
          <defs>
            <filter
              id="filter0_f_15_2423"
              x="0"
              y="0"
              width="6"
              height="60"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1"
                result="effect1_foregroundBlur_15_2423"
              />
            </filter>
          </defs>
        </svg>
        <div className="subHeading">
          EXPOSYNC
        </div>
      </div>
    );
}