import Popup from "../../components/macroCompnents/popup/Popup";
import Tips from "../../components/macroCompnents/tips";
import ExhibitorHeading from "../../components/microComponents/ExhibitorHeading";
import Layout from "../../layout/Layout";
import { addRemoveVisitList, exhibitorList, getList,visitExhibitorList } from "../../service/commonData";
import { useNavigate } from "react-router-dom";
import search from './../../assets/icons/search.png'
import './../exhibitor/exhibitor.scss'
import stallIcon from './../../assets/icons/stall.png';
import unsaved from './../../assets/icons/saveUnselect.png';
import share1 from './../../assets/icons/share1.png';
import locationIcon from './../../assets/icons/locationIcon.png';
import stallDummyIcon from './../../assets/icons/stallDummy.png';
import { useState } from "react";
import ShareFlow from "../../components/macroCompnents/share";

export default function VisitList(){
    let exhibitorData=visitExhibitorList();
    const navigate=useNavigate()
    const [exhibitor,setExhibitorStep]=useState({data:exhibitorData,selectedParams:""})
    const [share,setShare]=useState({flag:false,shopId:null})
    console.log(exhibitor);
   
    function handelSearch(){

    }
    function handelShareClick(id){
      console.log("clicked")
        setShare({flag:true,shopId:id})

    }
    function handelSaveClick(id){
      addRemoveVisitList(id);
      let updatedData=visitExhibitorList();
      let copyData={...exhibitor}
      copyData.data=updatedData;
      
      setExhibitorStep(copyData);
      
    }
    function handelClose(){
      console.log("clicked on close")
      
      if(share.flag){
        let data={flag:!share.flag,shopId:null}
          setShare(data)
      }
      
     
  }
    return (
      <Layout>
        {share.flag && 
         <div className="popup">
        <Popup handelClose={handelClose}>
            {share.flag && <ShareFlow selectedShopId={share.shopId} handelClose={handelClose} state={0}/>}
        </Popup>
        </div>
        
       }
        <section className="pageContent">
          <ExhibitorHeading
            heading="Explore"
            highlight="Profiles"
            subHeading="Lorem ipsum dolor sit amet consectetur. Sit hac pretium quis felis habitant orci."
          />
          <Tips tips="Click on Tags of your Choice to filter out profiles on basis of your preferred tags" />

          <div className="exhibitorlistContainer">
            <div className="profileNo">There Are <span> {exhibitor?.data?.length} Profiles</span> In This Exhibition</div>
            <div className="searchContainer exhibitorSearch">
              <img alt="search" src={search} />
              <input
                className="shareSearch bg-transparent"
                placeholder="Search using Company Names/stall Number"
                onChange={handelSearch}
              />
            </div>
            <div className="exhibitors">
              {exhibitor.data.map((item,index)=>{
                return (
                  <div className="exhibitorContainer">
                    <div onClick={()=>navigate(`/profile?id=${item.id}`)} className="exhibitorContent">
                      <div className="h">
                        <div className="id">
                          <img alt="stallIncon" src={stallIcon} />
                          {item.shopId}
                        </div>
                        <div className="exhibitorLocation">
                          <img alt="location" src={locationIcon} />
                          {item.city || ""}, {item.state || ""}, {item.country}
                        </div>
                      </div>
                      <div className="b">
                        <div className="name">
                          <div className="exhibitorIcon">
                            <img alt="dummy" src={stallDummyIcon} />
                          </div>
                          <div className="exhibitorheadsub">
                            <div className="head"> {item.heading || ""}</div>
                            <div className="sub">{item.description}</div>
                            <div className="tag">
                          {item.tags.map((item,index)=>{
                            return <div className={ `tagitem tag-${index}`}>{item}</div>
                          })}
                          
                        </div>
                          </div>
                        </div>
                       

                      </div>
                    </div>
                    <div className="exhibitorIcon">
                      <img onClick={()=>handelShareClick(item.id)} alt="share" src={share1} />
                      <img onClick={()=>handelSaveClick(item.id)} alt="unsaved" src={unsaved} />
                    </div>
                  </div>
                );

              })
            }


            </div>
          </div>
        </section>
      </Layout>
    );
}