
export default function(props){
   return (
    <>
    <div className="cusSupTitle">
        <div>{`Numbers of ${props.type}`}</div>
    </div>
    <div className="cusSupContent">
        <span className="cusSupTitle">{`${props.count} ${props.type} `}</span> Shared their card with you
    </div>
    </>
   )
}