import { useState } from "react";
import leftArrow from "./../../assets/icons/LeftArrow.png";
import rightArrow from "./../../assets/icons/RightArrow.png";

export default function FacilitiesGroup(props) {
  let facilities = props.facilities;
  const [activeIndex, setIndex] = useState(props.activeFacility);
  const admin=true;
  function getTransformx(index) {
    let stt = Math.abs(activeIndex - index);
    if (stt > 0) {
      let returnString = "translateX";
      returnString =
        returnString +
        "(" +
        `${index < activeIndex ? "-" : ""}` +
        300 * stt +
        "px)";
      let scale = `scale(${1 - 0.2 * stt})`;
      let returnObject = {
        transform: `${returnString} ${scale} rotateY(-50deg)`,
        zIndex: getZindex(stt),
        filter: "blur(5px)",
        opacity: stt > 2 ? 0 : 0.6,
      };
      // console.log(returnObject)

      return returnObject;
    }
    return {
      transform: "none",
      zIndex: 1,
      opacity: 1,
    };
  }
  function getZindex(stt) {
    return -stt;
  }
  function setNext() {
    console.log('clicked')
    if (activeIndex + 1 < facilities.length) {
   
      setActiveData(activeIndex+1);
    }
  }
  function setPrev() {
    if (activeIndex > 0) {
      setActiveData(activeIndex - 1);
    }
  }
  function setActiveData(data){
    setIndex(data);
}

  return (
    <div className="facilitiesContent">
        
      <img width='40' height='40' onClick={setPrev} className="profileLeftArrow" src={leftArrow} />
      <div className="facilitiesCardParent">
        {facilities.map((data, index) => {
          return (
            <div key={index} style={getTransformx(index)} className="facilitiesCard">
              <div contentEditable={props.isAdmin} className={`facilitiesCardHeading`}> {!data.name && props.isAdmin?'Type here to edit':data.name}</div>
              <div contentEditable={props.isAdmin} >{!data.content && props.isAdmin?'Type here to Edit':data.content}</div>
            </div>
          );
        })}
      </div>
     
      <img width='40' height='40' className="profileRightArrow"  onClick={setNext} src={rightArrow} />
    
      
      <div className="profileCircleGroup">
        {facilities.map((data, index) => (
          <div key={index}
            className={`profileCircle ${
              activeIndex === index ? "profileActiveCircle" : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}
