export default function IndAnalytics(props){
    let heading='';
    let subHeading='';
    let statics='';
if(props.type==='industry'){
    statics=[
        {name:"food",count:3}, {name:"Travel",count:2}, {name:"Influencer",count:5}, {name:"Medical",count:4}
    ]
    heading="Industry Analytics"
    subHeading="Analytics of people’s industry who share their Card"
}
else{
    statics=[
        {name:"Delhi",count:3}, {name:"Mumbai",count:2}, {name:"Kolkata",count:5}
    ]
     heading='Top 3 Cities';

     subHeading='We have a list of 3 cities that loves you the Most!'


}
     
    return (
    <div>
        <div className="analyticsHead">{heading}</div>
        <div className="analyticssubHead">{subHeading}</div>
        <div className="anyliticsContent">
            {statics.map((item,index)=>{
                return (
                    <div className="analyticsGroup flex spaceBetween" key={index} >
                    <div className="analyticsContentHead" >{item.name}</div>
                    <div  className="analyticsValue">{item.count}</div>
                    </div>
                )
            })}

        </div>
    </div>)
}