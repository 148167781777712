import Layout from "../../layout/Layout";
import './cardManagement.scss'
import CardManagementHeader from "../../components/macroCompnents/CardManagementHeader";
import UserInputFields from "../../components/macroCompnents/UserInputFieds";
import Card from "../../components/macroCompnents/card/Card";
import delete_icon from "../../assets/icons/delete.png";
import share_icon from '../../assets/icons/share.png';
import download_icon from "../../assets/icons/download.png"
// import downloadjs from 'downloadjs';
import delteIcon from './../../assets/icons/deleteCross.png'
import html2canvas from 'html2canvas';
import Popup from "../../components/macroCompnents/popup/Popup";
import React,{useState,useEffect} from "react";
import ShareFlow from "../../components/macroCompnents/share";
import rightArrow from './../../assets/icons/buttonRightArrow.png';
import leftArrow from './../../assets/icons/buttonLeftArrow.png';
import addButton from './../../assets/icons/Add Button.png';
import AddCardName from "../../components/macroCompnents/AddCardName";


export default function CardManagement(){
    let usersData=[
       
        {
            cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {
        cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
     {
        cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {
        cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {
        cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
     {
        cardName:"asd",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          industry:"food",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    }
]
    function download(){
        console.log("download clicked")
        console.log(document.getElementById("active"))
        const downloadimnage=document.getElementById("active")
        html2canvas(downloadimnage).then(function (canvas){
            const link=document.createElement('a');
            link.download='image.png';
            link.href=canvas.toDataURL('image/png');
            link.click();
        })
        // const canvas=document.getElementById("active");
        
        
    }
    const [activedata,setActiveData]=useState({data:usersData,activeIndex:0,goToLast:false})
    // let activeIndex=1;
    const [share,setShare]=useState(false)
    const [deleteFlow,setDeleteFlow]=useState(false)
    const [addCardPopup,setAddCardPopup]=useState(false)
    function handelClose(){
        if(share){
            setShare(!share)
        }
        else if(deleteFlow){
            setDeleteFlow(!deleteFlow)
        }   
        else{
            setAddCardPopup(!addCardPopup)
        }   
    }
    useEffect(() => {
      setDeleteFlow(false)
      setAddCardPopup(false)
       
      }, [activedata]);
    function handelDelete(){
        if(activedata.data.length>0){
        let copyData={...activedata}
        copyData.data=copyData.data.filter((item,index)=>index!==copyData.activeIndex)
        copyData.activeIndex=copyData.activeIndex>=copyData.data.length?copyData.activeIndex-1:copyData.activeIndex
        setActiveData(copyData)
        }
    }
    function openDelete(){
        setDeleteFlow(!deleteFlow)
    }
    if(activedata.activeIndex<activedata.data.length && activedata.goToLast){
        if(activedata.activeIndex+1<activedata.data.length){
            let copyData={...activedata};
            if(copyData.activeIndex+1===activedata.data.length-1)
               copyData.goToLast=false
            copyData.activeIndex+=1;
      
      setTimeout(() => {
        setActiveData(copyData)
      },200);
      
        }
    }
    function openShare(){
       setShare(!share);
    }
    function setNext(){
        if(activedata.activeIndex+1<activedata.data.length){
            let copyData={...activedata};
            copyData.activeIndex+=1;
      setActiveData(copyData)
        }
    }
    function setPrev(){
       if(activedata.activeIndex>0){
        let copyData={...activedata};
        copyData.activeIndex-=1;
  setActiveData(copyData)
       }
    }
    function addNewCard(name){
        if(activedata.data.length<10){
            let copyData={...activedata}
            let newData=JSON.parse(JSON.stringify(copyData.data[copyData.activeIndex]))
            newData.cardName=name;
            // copyData.activeIndex=activedata.data.length;
            // goToLast;
            copyData.goToLast=true;
            copyData.data.push(newData);
            setActiveData(copyData);
        }
    }
    function updateData(data){
        console.log("updateDAta :",data)
       let copyData={...activedata};
       copyData.data[activedata]=data;
       setActiveData(copyData);
    }

return (
   
    <Layout>
         {(share || deleteFlow || addCardPopup) && 
         <div className="popup">
        <Popup handelClose={handelClose}>
            {share && <ShareFlow handelClose={handelClose} state={1} id={2}/>}
            {deleteFlow && <div className="commonPopup">
                <div className="flex deletePopupHeading"> 
                    <div><img alt="asd" width={45}  src={delteIcon}/></div>
                    <div className="deleteHeading"> <div className="deleteCard">Delete Card</div> <div> Are You Sure you want to delete this Card</div></div>
                </div>
                <div className="actionButton"><button onClick={handelClose} className="secondary btn">Cancel</button> <button onClick={handelDelete} className="primary btn">Delete</button></div></div>}
            {addCardPopup && <AddCardName handelClose={handelClose} addCard={addNewCard}/>}
        </Popup>
        </div>
        
       }
        
         
        <section className="pageContent">
    <CardManagementHeader/>
   <div className="cardGroup">
    <img alt="add Button" className="addButton" onClick={()=>setAddCardPopup(true)} src={addButton} />
    {
        activedata.data.map((data,index)=>{
            return(<Card swipeLeft={setNext} swipeRight={setPrev} key={`for-${index}`} index={index} activeIndex={activedata.activeIndex} userData={data}/>)
        })
    }
   </div>
   <div className="prevnextButton">
      <div  onClick={setPrev} id="next"><img alt="prev"  className="arrowIcon" src={leftArrow} /></div>
      <div className="circleGroup">
        {
            activedata.data.map((data,index)=><div key={index} className={`circle ${activedata.activeIndex===index?"activeCircle":""}`}></div>)
        }
        
      </div>
      <div  onClick={setNext} id="prev"><img  alt="next" className="arrowIcon" src={rightArrow}/></div>
   </div>
   
    <div className="groupicon">
        <div className="groupIconContainer">
            
            <img alt="delete" onClick={openDelete}  src={delete_icon}/>
            <span className="iconNames"> Delete</span>
            <span className="divide">|</span>
        </div>
        <div className="groupIconContainer">
            
            <img alt="share"  onClick={openShare} src={share_icon}/>
            <span className="iconNames"> Share</span>
            <span className="divide">|</span>
        </div>
        <div className="groupIconContainer">
            
            <img alt="download" onClick={download} src={download_icon}/>
            <span className="iconNames"> Download</span>
            
        </div>
        
    </div>
    <UserInputFields onChange={updateData} userData={activedata.data[activedata.activeIndex]}/>
    </section>
    </Layout>
)
}