import {initiateShopList,getShopList,getExhibitorList,getVisitList,getVisitedList} from "./apiService";
let companyList=[]
let visit=[]
let exhibitorListData=[]
let visitedList=[]
// let exhibitorList=[]
export function SetInitialData(){
    getVisitListData();
    getExhibitorListData();
    getVisitListData();
    getVisitedListData()

}
export function setShopList(){
        companyList=getShopList()
}
export function getList(){
    return companyList;
}
export function addRemoveVisitList(data){
    if(visit.includes(data)){
        visit=visit.filter(item=>item!==data);
    }
    else{
        visit.push(data)
    }

}
export function exhibitorList(){
       exhibitorListData.forEach(element => {
       visit.includes(element.id)? element["toVisit"]=true:element["toVisit"]=false;
       visitedList.includes(element.id)?element["visited"]=true:element["visited"]=false;
       });
    return exhibitorListData;
}
export function visitExhibitorList(){
    return exhibitorListData.filter(element=>{
       return visit.includes(element.id)
    })
}
function getExhibitorListData(){
    exhibitorListData=getExhibitorList()
}
function getVisitListData(){
      visit=getVisitList()
}
function getVisitedListData(){
      visitedList=getVisitedList();
}
export function getCardData(){
    return [
        {
            id:1,
            cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
      
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {  id:2,
        cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
     {  id:3,
        cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {   id:4,
        cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
    {   id:5,
        cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    },
     {  
        id:6,
        cardName:"one",
        personalDetails:{
            firstName:"Tanishq",
            lastName:"sharma",
            company:"Tanishq",
            jobTitle:"Founder"
        },
        contactDetails:{
           email:"Tanishq424@gmail.com",
           phoneNo:"98766834682643"
        },
        companyDetails:{
          country:"India",
          state:"Rajasthan",
          city:"Jaipur",
          companySize:"10-15",
          website:"www.tanishq.com"
        }
    }
]
}