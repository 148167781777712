import { useState } from "react"

export default function AddCardName(props){
    const [cardName,setCardName]=useState("")
    return (
        <div className="commonPopup">
        <div className="addCardNamePopupHeading"> 
            <div className="addCardHeading">
                <div className="deleteCard">Add Card Name</div>
                <div className="addCardInput"> <input label="Add Card Name" onChange={(e)=>setCardName(e.target.value)} vlaue={cardName}/></div></div>
        </div>
        <div className="actionButton"><button onClick={()=>props.handelClose()} className="secondary btn">Cancel</button> <button onClick={()=>props.addCard(cardName)} className="primary btn">Add Card</button></div></div>   
    )
}