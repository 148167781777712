import './macroComponents.scss'
export default function AboutUs(props){
    return(
        <>
        <div className="aboutUsHeading">
            About Us
        </div>
        <div className="aboutUsContent">
            <div className='c'>{props.content}</div>
            <div className='brochure'> 

                <div className='brochureContent'>Download Our Brochur</div>
                <button className="submitBrochure">Download</button>
            </div>

        </div>
        </>
    )
}