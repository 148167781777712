import profileIcon from './/../../assets/icons/restrurantLogo.png'
import './macroComponents.scss'
import locationGreen from './../../assets/icons/locationGreen.png'
import urlIcon from './../../assets/icons/web.png'
export default function ProfileHeader(props){
    return(
        <div className="flex detailsContainer">
            <div className='profileImage'>
                <img width='80' height='80'  alt="profile Icon" src={profileIcon}/>
            </div>
            <div className='profileHeaderContent'>
                <div id='stallNo'>Stall Number: {props.shopId}</div>
                <div id='profileName'>{props.name}</div>
                <div id='profileLocation'> <img alt="location Green" src={locationGreen} /> {`${props.city||''},${props.state},${props.country}`}</div>
                <div id='profileUrl'> <a href={props.website}><img width='16px' height='16px' src={urlIcon}/> Official Website </a> </div>
                <div className="profileTag">
                          {props.tags.map((item,index)=>{
                            return <div key={index} className={ `tagitem tag-${index}`}>{item}</div>
                          })}
                          
                </div>

            </div>
        </div>
    )

}