
import { useNavigate} from "react-router-dom";
import './layout.scss'
import iconLogo from './../assets/icons/exposyncLogo.png'
export default function Layout({children}){
  const navigate=useNavigate();
  
    
   function handelNavigate(data){
    navigate(data)
    }
   return (
    <section id="background">
        
       
        <header>
        <section className="top-nav">
    <div>
      <img width={150}  src={iconLogo}/>
    </div>
    <input id="menu-toggle" type="checkbox" />
    <label className='menu-button-container' htmlFor="menu-toggle">
    <div className='menu-button'></div>
  </label>
    <ul className="menu">
      <li onClick={()=>handelNavigate('/cards')}>Cards </li>
      <li onClick={()=>handelNavigate('/exhibitor')}>Exhibitor</li>
      <li onClick={()=>handelNavigate('/visitlist')}>Visit List</li>
    
     
    </ul>
  </section>
            
    </header>
        <div className="contentLayout">
        {children}
        </div>
    </section>
   )
}