import './App.scss';
import CardManagement from './screens/cardManagement/CardManagement';
import Exhibitor from './screens/exhibitor/Exhibitor';
import Profile from './screens/profile/Profile';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SetInitialData } from './service/commonData';
import VisitList from './screens/visitedList/VisitList';
import Dashboard from './screens/dashboard/Dashboard';


function App() {
  SetInitialData();
  return (
    // <div className="App">
    //  {/* <CardManagement/> */}
    //  {/* <Exhibitor/> */}
    //  <Profile/>
    // </div>
    <BrowserRouter>
      <Routes>
        <Route path="/cards" element={<CardManagement />}/>
          {/* <Route index element={<Home />} /> */}
          <Route path="/exhibitor" element={<Exhibitor />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/visitlist" element={<VisitList />} />
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path="*" element={<CardManagement />} />

        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
