import { useEffect, useState } from "react";
import "./macroComponents.scss";
import supplierIcon from './../../assets/icons/supplier.png';
import customerIcon from './../../assets/icons/customer.png';
import shareSelect from './../../assets/icons/shareSelect.png';
import shareUnselect from './../../assets/icons/shareUnselect.png';
import add from './../../assets/icons/add.png';
import Button from "../microComponents/Button";
import { setShopList,getList, getCardData } from "../../service/commonData";
import search from "./../../assets/icons/search.png"
import RestrurantLogo from "./../../assets/icons/restrurantLogo.png"
import { getShopList } from "../../service/apiService";

export default function ShareFlow(props){
   
    
    const [step,setStep]=useState({state:props.state,user:"",selectedOption:[],selectedCard:props.id?props.id:null})
    setShopList()
    let shopList=getList()
    let id=props.id?props.id:null
    function handelStep0(data){
     
      const copyData={...step};
      copyData.state=1;
      copyData.selectedCard=data;
      setStep(copyData)
    }
    function handelStep1(data){
         const copyData={...step};
         copyData.state=2;
         copyData.user=data;
         setStep(copyData)
    }
    function handelStep2(data){
         shopList="asd"
        const copyData={...step}
        copyData.state=3;
        copyData.selectedOption=data;
        console.log(copyData);
        setStep(copyData);
    }
    function handelStep3(data){
        console.log(data)
        console.log("clicked")
        props.handelClose();
    }
return (
    <div className="shareContainer">
    {
        {   
            '0':<Step0 handelStep0={handelStep0}/>,
            '1':<Step1 handelStep1={handelStep1}/>,
            '2':<Step2 data={step} handelStep2={handelStep2} />,
            '3':<Step3 data={step} list={shopList} selectedShop={props.selectedShopId} handelStep3={handelStep3}/>
        }[step.state]
    }

    </div>
    
    
);
}
function Step0(props){
    let cardList=getCardData();
    const [cards,setCards]=useState({list:cardList,activeId:null})

  function handelOptionClick(id){
    const copyData={...cards};
     copyData.activeId=id;
     setCards(copyData)
  }
  function handelContinue(){
    props.handelStep0(cards.id)
  }

    return(
        <div>

        

        <div className="shareCardList">
             {
          cards.list.map((item,index)=>{return(<div key={index} className="optionsContainer flex" onClick={()=>handelOptionClick(item.id)}> <img alt="slelected" src={item.id===cards.activeId?shareSelect:shareUnselect}/><div className="option"> {item.cardName}</div></div>)})

        }
        </div>
        <Button  name="Continue" type="primary" className={`primaryGreen sharePopup ${!cards.activeId?'disabled':''} `} onClick={handelContinue}/>
        </div>
       
    )


}

function Step3(props){
    let shopList=getList()
    const [searchParams,setSearchParams]=useState("")
    const [list,setList]=useState({list:shopList,selectedItems:props.selectedShop?[props.selectedShop]:[]});
   
    useEffect(() => {
        let copyData={...list}
        if(searchParams.length>2){
           
            copyData.list=getFilteredList(searchParams)
            
        }
        else{
            copyData.list=getList()
        }
        setList(copyData)

       

         
        }, [searchParams]);
    
    function handelSelect(id){
        
        let copyData={...list}
        if(!copyData.selectedItems.includes(id)){
            copyData.selectedItems.push(id);
        }
        else{
            copyData.selectedItems=copyData.selectedItems.filter(item=>{return item!==id})
        }
        setList(copyData);
    }
    function handelSearch(event){
        let searchParams=event.target.value;
        setSearchParams(searchParams)
       // let searchParams=event.target.value;
       

        
    }
    function getFilteredList(value){

        let copyData=shopList;
       let filteredData=[];
       
        filteredData=copyData.filter(item=>{return item.name.toLowerCase().includes(value)})
           
       return filteredData
        
       
           
            // filtereddata=filtereddata.sort((a,b)=>{
            //     if(!a.active && b.active) return -1;
            //     if(a.active && !b.active) return 1;
            //     return a.id-b.id;
            // })
          
    }
    

    console.log(shopList);
    function handelShare(){
       let sharedData=list.list.filter((item)=>item.selected);
       props.handelStep3(sharedData.map(item=>item.id))
    }

  return(
    <div >
    <h1>
       Share With
   </h1>
   <div className="searchContainer">
    <img src={search}/>
    <input className="shareSearch" placeholder="Search using Company Names" onChange={handelSearch}/>
   </div>
   <div className="shareCompanyItemContainer">

  
   {
    list.list.map((item,index)=>{
        return <div key={item.id} className={`${(list.selectedItems).includes(item.id)?"selected":"notSelected"} companyList`}onClick={()=>handelSelect(item.id)}>
            <img src={RestrurantLogo}/>
            <div>
                <h1>
                {item.name}
                </h1>
                <h3>
                    {item.description}
                </h3>
                
            </div>
        </div>
    })
   }
    </div>

  
   <Button  name="Share" type="primary" className={ ` ${list.selectedItems.length>0?"":"disabled "}primaryGreen sharePopup`} onClick={handelShare}/>
   </div>
  )
}
function Step2(props){

    let optionsData=[{option:"some Product",id:1},{option:"wanna Buy something",id:2},{option:"i need quote on something",id:3}]
    // const [options,setOption]=useState(optionsData)
    const [selectedId,setSelectedId]=useState(null);
    const [addNewRequirement,enableAddNewRequirement]=useState({enable:false,value:""})
    function handelOptionClick(index){
    //    const copyData=[...options];
    //    copyData.forEach((item,i)=>{
    //     item.selected=i===index?true:false;
    //    })
    //    copyData[index].selected=!copyData[index].selected
       setSelectedId(index);
    }
    function handelAdd(){

    }
    function handelContinueWithoutRequirement(){
        props.handelStep2(null)
    }
    function handelContinue(){
        const selectedOptions=optionsData.filter(item=>item.id===selectedId)
        props.handelStep2(selectedOptions)
    }
    return(
        <div >
        <h1>
           You Are a
       </h1>
       <div className="groupIcon" >
           { props.data.user==="supplier" && <div className="iconContainer">
               <img alt="supplier Icon" src={supplierIcon}/>
               <div className="header">Supplier</div>
               <div className="subHeader">
                   You are here to provide something to the exhibitor
               </div>
           </div>}
           {props.data.user==="customer" && <div className="iconContainer">
               <img alt="customer Icon" src={customerIcon}/>
               <div className="header">Customer</div>
               <div className="subHeader">
               Buying something from the Exhibitor?
               </div>
           </div>}
       </div>
       <div className="optionBox">
        <div className="optionHeader">
            choose Your Requirement
        </div>
        {
          optionsData.map((item,index)=>{return(<div key={index} className="optionsContainer flex" onClick={()=>handelOptionClick(item.id)}> <img alt="slelected" src={item.id===selectedId?shareSelect:shareUnselect}/><div className="option"> {item.option}</div></div>)})

        }
        {!addNewRequirement.enable &&<div className="optionsContainer flex" onClick={()=>enableAddNewRequirement({enable:true,value:""})}> <img alt="add" src={add}/><div className="option"> Add New Requirement</div></div>}
        {addNewRequirement.enable &&<div className="addNewRequirementInput" > <input value={addNewRequirement.value} onChange={(e)=>{let copyData={...addNewRequirement};copyData.value=e.target.value; enableAddNewRequirement(copyData)}}/></div>}

       </div>
       <div className="warning">Not selecting or adding any requirement will deprioritise your enquiry</div>
       <Button type="secondary" name="Continue without Requirement" className="secondary  sharePopup sharePrimarybtn" onClick={handelContinueWithoutRequirement}/>
       <Button  name="Share" type="primary" className={`${selectedId || (addNewRequirement.enable && addNewRequirement.value)?"":'disabled '}primaryGreen sharePopup`} onClick={handelContinue}/>
       </div>
    )
}
function Step1(props){
    return(
        <div >
         <h1>
            Are You a
        </h1>
        <div className="groupIcon" >
            <div onClick={()=>props.handelStep1("supplier")} className="iconContainer">
                <img alt="supply icon" src={supplierIcon}/>
                <div className="header">Supplier</div>
                <div className="subHeader">
                    You are here to provide something to the exhibitor
                </div>
            </div>
            <div onClick={()=>props.handelStep1("customer")} className="iconContainer">
                <img alt="customer icon" src={customerIcon}/>
                <div className="header">Customer</div>
                <div className="subHeader">
                    Wanna Buy something from Exhibitor
                </div>
            </div>
        </div>
        </div>
       
    )
}