import expoLogo from './../../assets/icons/exposyncLogo.png'
import frame1 from './../../assets/icons/headerFrame1.png';
import frame2 from './../../assets/icons/headerFrame2.png';
export default function DashboardHeader(){
    return(
 <>
    <div className="headerLeftImage">
        <img width={200} height={50} alt="logo" src={expoLogo}/>
    </div>
    <div className='headerRightImage'>
        <img width={250} height={80} alt="frame1" src={frame1}/>
        <img width={250} height={80} alt="frame2" src={frame2}/>
    </div>
    </>
    )
}