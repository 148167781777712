import './microComponents.scss';
export default function Input(props){
    let error="error"
    function handelChange(event){
        console.log(event.target.value)
        console.log(props.label)
        props.onChange(event.target.value,props.passIndex?props.index:undefined)
    }
    
 return (
   
    <div className='inputContainer'>
        <label>
            {props.label}
        </label>
       <input onChange={handelChange} type={props.type?props.type:'text'} value={ props.value?props.value:''} placeholder={props.placeholder?props.placeholder:''}/>
       {/* {error&&<label className='error'>{error}</label>} */}

       
    </div>
    
 )
}