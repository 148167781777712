import './macroComponents.scss';
import women from '././../../assets/icons/woman with glasses.png'
export default function Tips(props){
return(
    <div className='tipsContainer'>
        <div className='tips'>
            <div className='tipHeading'>Quick Tip</div>
            <div className='content'>
            {props.tips}
            </div>
           
        </div>
        <img alt="women" src={women}/>
    </div>
)
}