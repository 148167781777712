import cardGroup from './../../assets/icons/cardGroup.png'
export default function CardRecieved(props){
    return (
        <>
        <h1>
            cards recieved
        </h1>
        <img  width={200}alt ="card" src={cardGroup}/>
        <h3 className='green'>
             {`${props.length} Cards in Total`}
        </h3>
        </>
    )
}