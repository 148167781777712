import CardRecieved from '../../components/macroCompnents/CardRecieved';
import CountSuppCus from '../../components/macroCompnents/CountSuppCus';
import DashboardHeader from '../../components/macroCompnents/DashboardHeader';
import IndAnalytics from '../../components/macroCompnents/IndAnalytics';
import mailIcon from './../../assets/icons/mailbig.png';
import whatsappIcon from './../../assets/icons/whatsapp.png'
import searchIcon from './../../assets/icons/searchBlack.png'
import './dashboard.scss';
import Tabulator from '../../components/microComponents/tabulator';
import 'react-tabulator/lib/styles.css';
import allSelected from './../../assets/icons/allSelected.png';
import allUnselected from './../../assets/icons/allUnselected.png'
import { useState,useRef } from 'react';
import XLXS from "xlsx";
import { saveAs } from 'file-saver';
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
import selected from '././../../assets/icons/selected.png';
import unselected from './../../assets/icons/unselected.png';
import { ReactTabulator } from 'react-tabulator';
export default function Dashboard(){
    const [select,setSelect]=useState(false)
    function handelSearch(event){

    }
    function returnTitle(){
        return <img src={allSelected}/>
    }
    var infoIcon = function(value, data, cell, row, options){ //plain text value
        return <img src={allSelected}/>
    };
    function getInputField(){
        return <input value="hi"/>
    }
    var editCheck = function(cell){
        //cell - the cell component for the editable cell
    
        //get row data
        // var data = cell.getRow().getData();
        // console.log(data);
    
         // only allow the name cell to be edited if the age is over 18
    }
    function getselected(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="38" height="34" viewBox="0 0 38 34" fill="none">
        <path d="M0.929688 4.25C0.929687 3.12283 1.41696 2.04183 2.28432 1.2448C3.15167 0.447766 4.32806 0 5.55469 0H28.6797C29.9063 0 31.0827 0.447766 31.9501 1.2448C32.8174 2.04183 33.3047 3.12283 33.3047 4.25V25.5C33.3047 26.6272 32.8174 27.7082 31.9501 28.5052C31.0827 29.3022 29.9063 29.75 28.6797 29.75H5.55469C4.32806 29.75 3.15167 29.3022 2.28432 28.5052C1.41696 27.7082 0.929688 26.6272 0.929688 25.5V4.25ZM24.8178 9.826C24.7035 9.73397 24.5706 9.66354 24.4267 9.61874C24.2827 9.57395 24.1306 9.55567 23.979 9.56494C23.8273 9.57422 23.6792 9.61087 23.543 9.6728C23.4068 9.73473 23.2852 9.82073 23.1852 9.92587L15.9054 17.5695L12.1546 14.1227C12.0471 14.024 11.9194 13.9456 11.779 13.8921C11.6385 13.8387 11.488 13.8112 11.3359 13.8112C11.1839 13.8112 11.0334 13.8387 10.8929 13.8921C10.7524 13.9456 10.6248 14.024 10.5173 14.1227C10.4098 14.2215 10.3245 14.3388 10.2664 14.4679C10.2082 14.597 10.1782 14.7353 10.1782 14.875C10.1782 15.0147 10.2082 15.153 10.2664 15.2821C10.3245 15.4112 10.4098 15.5285 10.5173 15.6273L15.1423 19.8773C15.2544 19.9801 15.3882 20.0607 15.5356 20.114C15.6829 20.1673 15.8406 20.1923 15.9989 20.1873C16.1572 20.1823 16.3127 20.1475 16.4557 20.085C16.5987 20.0226 16.7263 19.9338 16.8304 19.8241L24.9242 11.3241C25.1257 11.1121 25.2275 10.8353 25.2071 10.5544C25.1867 10.2735 25.0482 10.0115 24.8178 9.826ZM6.17213 31.875C6.57824 32.5214 7.16244 33.0581 7.86594 33.4311C8.56945 33.8041 9.36746 34.0004 10.1797 34H28.6797C31.1329 34 33.4857 33.1045 35.2204 31.5104C36.9551 29.9163 37.9297 27.7543 37.9297 25.5V8.5C37.9297 7.75399 37.716 7.02112 37.31 6.37506C36.9041 5.729 36.3203 5.19251 35.6172 4.8195V25.5C35.6172 27.1908 34.8863 28.8123 33.5852 30.0078C32.2842 31.2034 30.5196 31.875 28.6797 31.875H6.17213Z" fill="#40E18C"/>
        </svg>
    }
    let workloadsTableRef = useRef(null);
    // 
    function updateData(data){
        workloadsTableRef.updateData([data])
    }
    function rowClick(e,row){
        console.log(row.getData())
        row.updateData({select:false})
    }
    var customMutatorName = function(cell,formatterParams,onRender){
        // console.log("name",cell.getValue())
        // console.log(data.email)
        // console.log(data)
        // return `<h1>${data.name}</h1><div>asjsajh</div>`
    //    return "<div>hi</div>"
    // console.log(onRender)
        return `<div><div class="tabilatorTitleHead">${cell.getValue()}</div><div  class="tabilatorTitleEmail">${cell.getValue()}</div></div>`
    }
    var customMutatorCompany = function(value, data, type, params, component){
        console.log("post",data)
        // console.log(data1)
    //    return "<div>hi</div>"
        return `<div><div class="tabilatorTitleHead">${data.post}</div><div  class="tabilatorTitleEmail">${data.company}</div></div>`
    }
    var customMutatorNotes=function(value, data, type, params, component){
        console.log(value)
        return `<textarea class='tableinputField' >${data.notes}</textarea>`
    }
    function getData(){
        workloadsTableRef.current.download("json", "RTP_Workloads.json");
        //var table = Tabulator.prototype.findTable("#example-table")[0];
        //console.log(table)
    }
    function download(){
        const wb=XLXS.utils.book_new();
        const ws=XLXS.utils.aoa_to_sheet([["asd"],["asasd"]]);
         XLXS.utils.book_append_sheet(wb,ws,'sheet1')
        // wb.Sheets["Test Sheet"] = ws;
        const blob=XLXS.write(wb,{bookType:'xlsx',type:'binary'})
         saveAs(blob,"file.xlxs")
        // table.download("xlsx", "data.xlsx", {sheetName:"MyData"}); //download a Xlsx file that has a sheet name of "MyData"
    }

    let columns=[
        {title:'', vertAlign:'middle',cssClass:"nobg", field:"selected", editor:true, width:10,headerSort:false ,formatter:function(cell,formatterParams,onRender){
            // console.log(cell.getValue())
            console.log(cell.getRow())
            if(cell.getValue()){
              return `<img width="20" height="20" alt="fs" src="${selected}"/>`
            }
            else{
              return `<img width="20" height="20" alt="asd" src="${unselected}"/>`
            }
         
        },
        cellClick:function(e,cell){
            // console.log(e,cell)
            // console.log(cell.getData())
            // console.log(cell.getRow().getIndex())
            // let data={...cell.getData()}
            // data.select=!data.selected
            // updateData(data)
            console.log(cell.getData())
            cell.getRow().update({selected:!cell.getData().selected});
          
            
            
        }
        },
        { title: "NAME", headerSort:false, field: "nameandEamil", width: 150 ,formatter:function(cell,formatterParams,onRender){
            // console.log(cell.getValue())
            let cellData=cell.getData()
            return `<div><div class="tabilatorTitleHead">${cellData.name}</div><div  class="tabilatorTitleEmail">${cellData.email}</div></div>`
         
        },cellClick:function(e, cell){
            console.log(e)
            },
    },
        { title: "MOBILE NUMBER",width:150, headerSort:false, field: "mobile", hozAlign: "left",  },
        { title: "POST AND COMPANY", headerSort:false,formatter:"html", hozAlign:"center", width: 200, field: "postandcompany",formatter:function(cell,formatterParams,onRender){
            // console.log(cell.getValue())
            let cellData=cell.getData()
            return `<div><div class="tabilatorTitleHead">${cellData.post}</div><div  class="tabilatorTitleEmail">${cellData.company}</div></div>`
         
        }},
        { title: "COMPANY SIZE", cssClass:"tableData",width:150, headerSort:false, field: "companySize", hozAlign: "center" },
        { title: "TYPE", cssClass:"tableData dataType",headerSort:false, width:100, field: "type",  },
        { title: "INDUSTRY", cssClass:"tableData",headerSort:false, width:100, field: "industry"  },
        { title: "REQUIREMENT", cssClass:"tableData",headerSort:false, width:200, formatter:"textarea", field: "requirement",},
        { title: "NOTES", width:250,headerSort:false, field: "notes", hozAlign: "center", 
        formatter:function(cell,formatterParams,onRender){
            // console.log(cell.getValue())
            let cellData=cell.getData()
            return `<textarea class='tableinputField' >${cellData.notes}</textarea>`
         
        }}
      ];
    let data1=[
        {id:1, name:"Oli Bob",  email:"asdjhatjsd@gmail.com", selected:false, mobile:'172737282772', post:'developer', company:"Expo Sync" ,companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:'asdadafasd'},
        {id:2, name:"Oli Bob",  email:"asdjhajsd@gmail.com", selected:false, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
        {id:3, name:"Oli Bob",  email:"asdjhajsasd@gmail.com",selected:false, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'supplier' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
        {id:4, name:"Oli Bob",  email:"asdjhasjsd@gmail.com",selected:true, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
        {id:5, name:"Oli Bob",  email:"asdjhaasdjsd@gmail.com",selected:true, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
        {id:6, name:"Oli Bob",  email:"asdjhajsd@gmail.com",selected:false, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
        {id:7, name:"Oli Bob",  email:"asdhgjhajsd@gmail.com",selected:false, mobile:'172737282772', post:'developer', company:"Expo Sync", companySize:"12-50",type:'customer' ,industry:'Textile',requirement:"Lorem ipsum dolor sit amet consect", notes:''},
      ];
  return (
    <div className="dashboardPage">

        <div className='header'>
            <DashboardHeader/>   
        </div>
        <div className='dashboardStatics'>
            <div className='cardRecieved box1'>
              <CardRecieved length={9}/>
            </div>
            <div className='cardShared box3'>
                <div className='customerShared box'>
                    <CountSuppCus type="customer" count="5"/>
                </div>
                <div className='customerShared box'>
                    <CountSuppCus type="supplier" count="8"/>
                </div>
            </div>
            <div className='industryAnalytics box'> <IndAnalytics type='industry'/></div>
            <div className='industryAnalytics box'> <IndAnalytics/></div>
        </div>
        <div className='dashboardSearch'>
            <div className='' >
                <img height={30} alt="search" src={searchIcon}/>
                <input
                className="dashboardSearchField bg-transparent"
                placeholder="Search"
                onChange={handelSearch}
              />

            </div>
            <div>
                <button onClick={getData}>download</button>
                <img height={50} alt="mail" src={mailIcon} />
                <img height={50} alt="whatsapp" src={whatsappIcon} />
            </div>

        </div>
        <div className='tabulator-react'>
            <img onClick={()=>setSelect(!select)} width={20} src={select?allSelected:allUnselected} className='tableCheckbox'/>
            <ReactTabulator id="example-table"
            onRef={(r) => (workloadsTableRef = r)}
             data={data1}
           columns={columns}
             layout="fitDataStretch"
             events={{
                // rowClick: rowClick,
              }}
           />
        </div>
    </div>
  )
}